import {AbilityBuilder} from '@casl/ability'

function subjectName(item) {
  if (!item || typeof item === 'string') {
    return item
  }
  return item.__type
}

let permissions = JSON.parse(localStorage.getItem("permissions")) || {};

export default AbilityBuilder.define(
    {subjectName},
    can => {
      for (let moduleName in permissions) {
        let actions = permissions[moduleName];
        for (let action in actions) {
          if (actions[action]) {
            can(action.toString(), moduleName.toString());
          }
        }
      }
    }
)
