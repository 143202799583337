<template>
  <form data-vv-scope="component-data" @submit.prevent>
    <div class="vx-row">
      <div class="vx-col w-1/2">
        <menu-item-destination v-model="data.settings.destination" :institution="institution"/>
      </div>
    </div>

    <div class="vs-row mt-5">
      <div class="vx-col">
        <vs-checkbox
          name="advancedConfiguration" v-model="advancedConfiguration"
          @change="advancedConfiguration = !advancedConfiguration"
        >
          Personalizar
        </vs-checkbox>
      </div>
    </div>

    <template v-if="advancedConfiguration">
      <div class="vx-row mt-5">
        <div class="vx-col w-1/2">
          <label for="title">Título:</label>
          <editor id="title" name="title" :content.sync="data.settings.title.html" class="w-full"/>
        </div>
      </div>

      <div class="vx-row mt-5">
        <div class="vx-col w-1/2">
          <label for="description">Descripción:</label>
          <editor id="description" name="description" :content.sync="data.settings.description.html" class="w-full"/>
          <span class="text-danger">{{ errors.first("component-data.description") }}</span>
        </div>
      </div>

      <div class="vx-row mt-5">
        <div class="vx-col w-1/2">
          <label for="background-image">Imágen de fondo:</label>
          <uploader
            :downloaded-files="data.settings.background_image.file" :max-file-size="26214400"
            @files-removed="removedFiles()" @files-updated="fileUpload" name="background"
            :class-file="`upload-${getUniqueId()}`"
          />
        </div>
      </div>

      <div class="vx-row mt-5">
        <div class="vx-col w-1/2">
          <label for="custom_url">URL Personalizada (Opcional)</label>
          <vs-input id="custom_url" name="custom_url" v-model="data.settings.custom_url" class="w-full" />
        </div>
      </div>

    </template>
  </form>
</template>

<script>
  import BaseMenuComponent from "@/views/menus/components/BaseMenuComponent.vue";
  import Editor from "@/components/Editor.vue";
  import MenuItemDestination from "@/views/menus/components/MenuItemDestination.vue";
  import Upload from "@/utils/upload";
  import { v4 as uuidv4 } from 'uuid';


  const uploadsClient = new Upload("resource");

  export default {
    name: "DetailedTileItem",

    extends: BaseMenuComponent,

    components: {MenuItemDestination, Editor},

    data(){
      return {
        advancedConfiguration: false,
      };
    },

    created(){
      this.data.type = "detailed-tile-item";
    },

    mounted(){
      this.initializeComponent();
    },

    methods: {
      async fileUpload(files) {
        if (files.length > 0 && files[0].source === "Dashboard") {
          try{
            this.$vs.loading();
            const uploadResponse = await uploadsClient.uploadFiles({
              queryStrings: {resource_type: "menus", content_type: files[0].type},
              files: files,
              multiple: false,
            });
            this.data.settings.background_image = {
              file: {
                url: uploadResponse,
                meta: {title: files[0].name}
              },
            }
            this.$vs.loading.close();
          } catch (_) {
            this.$vs.loading.close();
            await notifier.notification("error");
          }
        }
      },

      getUniqueId(){
        return uuidv4();
      },

      initializeComponent(){
        const defaultSettings = {
          title: {},
          description: {},
          background_image: {},
          custom_url: "",
        }
        this.data.settings = {...defaultSettings, ...this.data.settings};

        if (
            Object.keys(this.data.settings.title).length !== 0 ||
            Object.keys(this.data.settings.description).length !== 0 ||
            Object.keys(this.data.settings.background_image).length !== 0
        ){
          this.advancedConfiguration = true;
        }
      },

      async removedFiles() {
        try {
          let body = this.data.settings.background_image.file.url;

          if (body) {
            await uploadsClient.removeFiles({data: {url: body.toString()}})
            this.data.settings.background_image.file.url = null;
          }
        } catch (_) {
          await notifier.notification("error");
        }
      },
    },
  }
</script>
