export const ComponentTypes = {
  LIST_WRAPPER: "list-wrapper",
  TILES_WRAPPER: "tiles-wrapper",
  MENU_WRAPPER_ITEM: "menu-wrapper-item",
  MOBILE_MENU_WRAPPER: "mobile-menu-wrapper",

  MENU_WRAPPERS: [
    self.LIST_WRAPPER,
    self.TILES_WRAPPER,
    self.MENU_WRAPPER_ITEM,
    self.MOBILE_MENU_WRAPPER,
  ],

  DETAILED_TILE_ITEM: "detailed-tile-item",
  LIST_TEXT_ITEM: "list-text-item",
  SIMPLE_LIST_ITEM: "simple-list-item",
  SIMPLE_TILE_ITEM: "simple-tile-item",


  MENU_ITEMS: [
    self.DETAILED_TILE_ITEM,
    self.SIMPLE_TILE_ITEM,
    self.SIMPLE_LIST_ITEM,
    self.LIST_TEXT_ITEM,
    self.MENU_WRAPPER_ITEM,
  ],
}

export const ComponentsNameMapper = {
  [ComponentTypes.LIST_WRAPPER]: "Contenedor de listas",
  [ComponentTypes.TILES_WRAPPER]: "Contenedor de mosaicos",
  [ComponentTypes.SIMPLE_TILE_ITEM]: "Mosaico símple",
  [ComponentTypes.DETAILED_TILE_ITEM]: "Mosaico detallado",
  [ComponentTypes.MENU_WRAPPER_ITEM]: "Contenedor de menu",
  [ComponentTypes.SIMPLE_LIST_ITEM]: "Elemento de lista simple",
  [ComponentTypes.LIST_TEXT_ITEM]: "Elemento de lista de texto",
  [ComponentTypes.MOBILE_MENU_WRAPPER]: "Contenedor de menú móvil",
}

export const MENU_WRAPPER_OPTIONS = [
  {
    label: "Contenedor de mosaicos",
    value: ComponentTypes.TILES_WRAPPER,
  },
  {
    label: "Contenedor de listas",
    value: ComponentTypes.LIST_WRAPPER,
  },
  {
    label: "Contenedor de menu",
    value: ComponentTypes.MENU_WRAPPER_ITEM,
  },
  {
    label: "Contenedor de menú móvil",
    value: ComponentTypes.MOBILE_MENU_WRAPPER,
  },
];

export const MENU_ITEM_OPTIONS = {
  [ComponentTypes.TILES_WRAPPER]: [
    {
      label: ComponentsNameMapper[ComponentTypes.SIMPLE_TILE_ITEM],
      value: ComponentTypes.SIMPLE_TILE_ITEM,
    },
    {
      label: ComponentsNameMapper[ComponentTypes.DETAILED_TILE_ITEM],
      value: ComponentTypes.DETAILED_TILE_ITEM,
    },
    {
      label: ComponentsNameMapper[ComponentTypes.MENU_WRAPPER_ITEM],
      value: ComponentTypes.MENU_WRAPPER_ITEM,
    }
  ],
  [ComponentTypes.LIST_WRAPPER]: [
    {
      label: ComponentsNameMapper[ComponentTypes.SIMPLE_LIST_ITEM],
      value: ComponentTypes.SIMPLE_LIST_ITEM,
    },
    {
      label: ComponentsNameMapper[ComponentTypes.LIST_TEXT_ITEM],
      value: ComponentTypes.LIST_TEXT_ITEM,
    },
    {
      label: ComponentsNameMapper[ComponentTypes.MENU_WRAPPER_ITEM],
      value: ComponentTypes.MENU_WRAPPER_ITEM,
    }
  ],
  [ComponentTypes.MOBILE_MENU_WRAPPER]: [
    {
      label: ComponentsNameMapper[ComponentTypes.DETAILED_TILE_ITEM],
      value: ComponentTypes.DETAILED_TILE_ITEM,
    },
    {
      label: ComponentsNameMapper[ComponentTypes.MENU_WRAPPER_ITEM],
      value: ComponentTypes.MENU_WRAPPER_ITEM,
    }
  ],
  ALL: [
    {
      label: ComponentsNameMapper[ComponentTypes.SIMPLE_TILE_ITEM],
      value: ComponentTypes.SIMPLE_TILE_ITEM,
    },
    {
      label: ComponentsNameMapper[ComponentTypes.DETAILED_TILE_ITEM],
      value: ComponentTypes.DETAILED_TILE_ITEM,
    },
    {
      label: ComponentsNameMapper[ComponentTypes.SIMPLE_LIST_ITEM],
      value: ComponentTypes.SIMPLE_LIST_ITEM,
    },
    {
      label: ComponentsNameMapper[ComponentTypes.LIST_TEXT_ITEM],
      value: ComponentTypes.LIST_TEXT_ITEM,
    }
  ]
}

export const MenuItemDestinations = {
  MODULE: "module",
  ENTRY: "entry",
  CATEGORY: "category",
  EXTERNAL_URL: "external",
  INSTITUTION: "institution",
}

export const SystemModules = {
  ASSETS: {
    label: "Activos eventuales",
    entryType: "assets",
    slug: "asset",
    institutions: ["bank", "companies"],
  },
  DISCOUNTS: {
    label: "Descuentos",
    entryType: "discounts",
    slug: "discount",
    institutions: ["bank"],
  },
  DOCUMENTS: {
    label: "Documentos",
    entryType: "documents",
    slug: "document",
    institutions: ["bank", "insurances", "companies"],
  },
  SALVAGES: {
    label: "Salvamentos",
    entryType: "salvages",
    slug: "salvage",
    institutions: ["insurances"]
  },
  STORES: {
    label: "Sucursales",
    entryType: "stores",
    slug: "store",
    institutions: ["bank", "insurances", "companies"],
  },
  TERMINOLOGIES: {
    label: "Glosario de términos",
    entryType: "terminologies",
    slug: "terminology",
    institutions: ["insurances"],
  },
  PAYMENTS: {
    label: "Zona de Pagos",
    entryType: "payments",
    slug: "payment",
    institutions: ["bank", "companies"],
  },
  STATIC_PAGES: {
    label: "Páginas estáticas",
    entryType: "static-pages",
    slug: "static_page",
    institutions: ["bank", "insurances", "companies"],
  },
  PRODUCTS: {
    label: "Productos",
    entryType: "products",
    slug: "product",
    institutions: ["bank", "insurances", "companies"],
  },
  PROJECTS: {
    label: "Proyectos inmobiliarios",
    entryType: "projects",
    slug: "project",
    institutions: ["bank"],
  },
  PROMOTIONS: {
    label: "Promociones",
    entryType: "promotions",
    slug: "promotion",
    institutions: ["bank", "insurances"],
  },
  PROVIDERS: {
    label: "Proveedores",
    entryType: "providers",
    slug: "provider",
    institutions: ["insurances"]
  },
}
