<template>
  <div :class="classFile"></div>
</template>
<script>
  export default {
    props: {
      maxNumberOfFiles: {
        type: Number,
        default: null
      },
      maxFileSize: {
        type: Number,
        default: 26214400
      },
      width: {
        type: String,
        default: "100%"
      },
      height: {
        type: Number,
        default: 470
      },
      classFile: {
        type: String,
        default: "file-upload"
      },
      downloadedFiles: {
        default: null
      }
    },

    $_veeValidate: {
      value () {
        return this.uppy?.getFiles();
      },

      name () {
        return this.$attrs.name;
      }
    },

    data() {
      return {
        uppy: null
      };
    },
    mounted() {
      const Uppy = require("@uppy/core");
      const Dashboard = require("@uppy/dashboard");
      const Spanish = require("@uppy/locales/lib/es_ES");

      require("@uppy/core/dist/style.css");
      require("@uppy/dashboard/dist/style.css");

      this.uppy = Uppy({
        restrictions: {
          maxNumberOfFiles: this.maxNumberOfFiles,
          maxFileSize: this.maxFileSize,
        },
        locale: Spanish
      }).use(Dashboard, {
        target: "." + this.classFile,
        proudlyDisplayPoweredByUppy: false,
        inline: true,
        hideUploadButton: true,
        width: this.width,
        height: this.height,
        metaFields: [
          {
            id: "name",
            name: "Título",
            placeholder: "Título del archivo"
          },
          {
            id: "caption",
            name: "Descripción",
            placeholder: "Descripción de la imagen"
          }
        ]
      });
      this.uppy.on("dashboard:file-edit-complete", () =>
        this.$emit("files-updated", this.uppy.getFiles())
      );
      this.uppy.on("file-added", file => {
        this.$emit("files-updated", this.uppy.getFiles());
      });
      this.uppy.on("file-removed", file => {
        this.$emit("files-removed", file);
      });
    },
    watch: {
      downloadedFiles: {
        handler(newVal, oldVal) {
          if (Array.isArray(newVal)) {
            newVal.forEach(file => {
              if (file.url !== null) {
                this.$http.get(file.url, {responseType: "blob"})
                    .then(response => {
                      this.uppy.addFile({
                        name: file.meta.title,
                        type: response.data.type,
                        data: response.data
                      });
                    });
              }
            });
          } else {
            if (newVal.url !== null && newVal.url !== undefined && newVal.url !== "") {
              this.$http.get(newVal.url, {responseType: "blob"})
                  .then(response => {
                    this.uppy.addFile({
                      name: newVal.meta.title,
                      type: response.data.type,
                      data: response.data
                    });
                  });
            }
          }
        },
        deep: true,
      }
    }
  };
</script>
