<template>
  <form data-vv-scope="component-data">
    <div v-bind="$attrs">
      <div class="vx-row">
        <div class="vx-col w-full">
          <label for="destination">Destino</label>
          <v-select
            id="destination" name="destination" placeholder="Seleccione..." :options="options"
            v-model="destination.type" :reduce="(destination) => destination.value" v-validate="'required'"
          />
          <span class="text-danger">{{ errors.first("component-data.destination") }}</span>
        </div>
      </div>

      <div class="vx-row mt-5" v-if="destination.type">
        <div class="vx-col w-full" v-if="destination.type === itemDestinations.MODULE">
          <label for="module">Módulo interno:</label>
          <v-select
            id="module" name="module" placeholder="Seleccione..." :options="systemModules" v-model="destination.settings"
            :reduce="(module) => ({type: module.slug})" v-validate="'required'"
          />
          <span class="text-danger">{{ errors.first("component-data.module") }}</span>
        </div>

        <template v-if="destination.type === itemDestinations.CATEGORY">
          <div class="vx-col w-full">
            <label for="module">Módulo</label>
            <v-select
              id="module" name="module" placeholder="Seleccione..." :options="systemModules"
              v-model="destination.settings.module" :reduce="(type) => type.entryType" v-validate="'required'"
              @input="loadCategories($event)"
            />
            <span class="text-danger">{{ errors.first("component-data.module") }}</span>
          </div>

          <div class="vx-col w-full mt-5">
            <label for="category">Categoría:</label>
            <v-select
              id="category" name="category" placeholder="Seleccione..." label="name" :options="filters.category.options"
              v-model="destination.settings.category" v-validate="'required'"
            />
            <span class="text-danger">{{ errors.first("component-data.category") }}</span>
          </div>
        </template>

        <div class="vx-col w-full" v-else-if="destination.type === itemDestinations.EXTERNAL_URL">
          <label for="url">Enlace externo:</label>
          <vs-input id="url" name="url" v-model="destination.settings.url" class="w-full" v-validate="'required'"/>
          <span class="text-danger">{{ errors.first("component-data.url") }}</span>
        </div>

        <template v-else-if="destination.type === itemDestinations.ENTRY">
          <div class="vx-col w-full">
            <label for="module">Módulo</label>
            <v-select
              id="module" name="module" label="label" placeholder="Seleccione..." :options="systemModules"
              v-model="destination.settings.module" :reduce="(module) => module.entryType" v-validate="'required'"
              @input="loadCategories($event)"
            />
            <span class="text-danger">{{ errors.first("component-data.category_type") }}</span>
          </div>

          <div class="vx-col w-full mt-5">
            <label for="category">Categoría:</label>
            <v-select
              id="category" name="category" placeholder="Seleccione..." label="name" :options="filters.category.options"
              v-model="destination.settings.category_id" :reduce="(category) => category.id" v-validate="'required'"
              @input="loadEntries"
            />
            <span class="text-danger">{{ errors.first("component-data.category") }}</span>
          </div>

          <div class="vx-col w-full mt-5">
            <label for="entry">Registro:</label>
            <v-select
                id="entry" name="entry" placeholder="Seleccione..." label="name" :options="entries"
                v-model="destination.settings.entry" v-validate="'required'"
            />
            <span class="text-danger">{{ errors.first("component-data.entry") }}</span>
          </div>
        </template>

        <div class="vx-col w-full" v-else-if="destination.type === itemDestinations.INSTITUTION">
          <label for="institution">Institución</label>
          <v-select
            id="institution" name="institution" label="name" :options="institutions"
            v-model="destination.settings.institution" v-validate="'required'"
          />
          <span class="text-danger">{{ errors.first("component-data.institution") }}</span>
        </div>
      </div>

      <div class="vx-row mt-5">
        <div class="vx-col w-full">
          <label for="target">Mostrar en:</label>
          <v-select
            id="target" name="target" placeholder="Seleccione..." :options="targets" v-model="destination.target"
            :reduce="(target) => target.value" v-validate="'required'"
          />
          <span class="text-danger">{{ errors.first("component-data.target") }}</span>
        </div>
      </div>

    </div>
  </form>

</template>

<script>
  import AttributeOptionsClient from "@/utils/attributeOptionsClient";
  import EntriesClient from "@/utils/entriesClient";
  import Notifier from "@/utils/notification";
  import vSelect from "vue-select"
  import {EventBus} from "@/utils/event-bus";
  import {MenuItemDestinations, SystemModules} from "@/views/menus/utils/constants";
  import CoreClient from "@/utils/coreClient";

  const notifier = new Notifier();

  export default {
    name: "MenuItemDestination",

    props: {
      institution: {
        type: String,
        required: true,
      },
    },

    components: {
      vSelect,
    },

    data(){
      return {
        destination: {
          type: "",
          settings: {},
          target: "",
        },
        entries: [],
        filters: {
          module: {
            options: [],
            selected: "",
          },
          category: {
            options: [],
            selected: "",
          },
          segment: {
            options: [],
            selected: "",
          },
        },
        entry_module: "",
        entry_category: "",
        institutions: [],
        options: [
          {
            label: "Módulo interno",
            value: MenuItemDestinations.MODULE,
            options: [],
          },
          {
            label: "Registro",
            value: MenuItemDestinations.ENTRY,
          },
          {
            label: "Enlace externo",
            value: MenuItemDestinations.EXTERNAL_URL,
          },
          {
            label: "Categoría",
            value: MenuItemDestinations.CATEGORY,
          },
          {
            label: "Institución",
            value: MenuItemDestinations.INSTITUTION,
          }
        ],
        segments: [],
        categories: [],
        featured: false,
        featuredPages: [],
      };
    },

    computed: {
      itemDestinations(){
        return MenuItemDestinations;
      },

      systemModules(){
        let response = [];
        for(const module of Object.values(SystemModules)){
          if (module.hasOwnProperty("institutions") && module.institutions.includes(this.institution)){
            response.push(module);
          }
        }
        return response;
      },

      targets(){
        return [
          {value: "_self", label: "Misma ventana"},
          {value: "_blank", label: "Ventana nueva"},
        ]
      },
    },

    watch: {
      destination: {
        handler(newVal, oldVal){
          if(!newVal.type && oldVal.type !== newVal.type){
            newVal.settings = {};
            return newVal;
          }

          if (newVal.type === MenuItemDestinations.INSTITUTION){
            this.loadInstitutions();
          }

          if (newVal.type && newVal.settings){
            this.$emit("input", {
              type: newVal.type,
              [`${newVal.type}_settings`]: newVal.settings,
              target: newVal.target,
            });
          } else {
            this.$emit("input", null);
          }

          return newVal
        },
        deep: true,
      },

      "filters.module.selected": {
        handler(newVal){
          if (!newVal){
            this.filters = {
              module: {
                options: [],
              },
              segment: {
                options: [],
              },
              category: {
                options: [],
              }
            }
            this.entries = [];
            this.destination.settings = {};
          }
          if (newVal === "products"){
            this.loadSegments();
          } else {
            this.loadCategories(newVal);
          }
        }
      },
    },

    created(){
      EventBus.$on("validate-fields", () => {
        this.$validator.validateAll("component-data").then((result) => {
          EventBus.$emit("validation-state", result);
        })
      });

      setTimeout(()=>{
        if (this.$attrs.value) {
          this.destination = {
            ...this.$attrs.value,
            settings: this.$attrs.value[`${this.$attrs.value.type}_settings`],
          };

          if (this.destination.type === this.itemDestinations.ENTRY){
            this.loadCategories(this.destination.settings.module);
            this.loadEntries();
          }

          if (this.destination.type === this.itemDestinations.CATEGORY){
            this.loadCategories(this.destination.settings.module);
          }

          if (this.destination.type === this.itemDestinations.INSTITUTION){
            this.loadInstitutions();
          }
        }
      }, 100);
    },

    methods: {
      async loadCategories(entryType){
        try{
          if(!entryType){
            return;
          }
          const attributeOptionsClient =  new AttributeOptionsClient(entryType);

          let pathParameters = {};
          let queryStrings = {};

          if (entryType === "products"){
            pathParameters = { institutionId: this.institution }
          } else {
            queryStrings = { institution_id: this.institution }
          }
          const categoriesResponse = await attributeOptionsClient.categories({
            pathParameters: pathParameters,
            queryString: queryStrings
          });
          this.filters.category.options = categoriesResponse.data.map((category) => ({...category, module: entryType}));
        } catch(_) {
          await notifier.notification('error');
        }

      },

      async loadEntries(){
        try{
          const entriesClient = new EntriesClient(this.destination.settings.module);
          const response = await entriesClient.all({
            queryStrings: {
              stage: "staging",
              institution_id: this.institution,
              ...(this.destination.settings.category_id ? { category_id: this.destination.settings.category_id } : {})
            },
          });
          this.entries = response.data.map((entry) => {
            return {
              id: entry.id,
              name: entry.name,
              slug: entry.slug,
              title: entry.title,
              category: entry.category,
              category_id: entry.category_id,
              institution: entry.institution,
              institution_id: entry.institution_id,
              segment_id: entry.segment_id || null,
              segment: entry.segment || null,
            };
          });
        } catch (_) {
          await notifier.notification("error")
        }

      },

      async loadInstitutions(){
        try{
          const institutionsClient = new CoreClient("institutions");
          const institutionsResponse = await institutionsClient.all({
            queryStrings: {
              stage: "staging",
            }
          });
          this.institutions = institutionsResponse.data;
        }catch(_){}
      },
    }
  }
</script>
