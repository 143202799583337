import axios from 'axios';

let qs = require('qs');

export default class CoreClient {
  constructor(type) {
    this.type = type;
  }

  async all({queryStrings} = {}) {
    let url = `${process.env.VUE_APP_CORE_V1_BASE_URL}/${this.type}`;
    return axios({
      url: url,
      method: 'get',
      params: {stage: "staging", ...queryStrings},
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'})
      },
    });
  }

  async searchMenus({queryStrings} = {}) {
    let url = `${process.env.VUE_APP_CORE_V1_BASE_URL}/${this.type}`;
    return axios.get(url, {
      params: {
        stage: "staging",
        ...queryStrings,
      },
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'});
      },
    });
  }

  async createMenu({data} = {}){
    let url = `${process.env.VUE_APP_CORE_V1_BASE_URL}/${this.type}`;
    return axios.post(
      url,
      data,
      {
        headers: { "x-api-key": process.env.VUE_APP_API_KEY }
      }
    );
  }

  async readMenu({pathParameters, queryStrings} = {}){
    let url = `${process.env.VUE_APP_CORE_V1_BASE_URL}/${this.type}/%(id)s`;
    return axios.get(`${sprintf(url, pathParameters)}`, {
      params: { stage: "staging", ...queryStrings },
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'});
      },
    });
  }

  async updateMenu({pathParameters, data} = {}) {
    let url = `${process.env.VUE_APP_CORE_V1_BASE_URL}/${this.type}/%(id)s`;
    return axios.put(
      `${sprintf(url, pathParameters)}`,
      data,
      {
        headers: {"x-api-key": process.env.VUE_APP_API_KEY}
      },
    );
  }

  async commitMenu({pathParameters} = {}) {
    let url = `${process.env.VUE_APP_CORE_V1_BASE_URL}/${this.type}/%(entryId)s/actions/commit`;
    return axios.post(
      `${sprintf(url, pathParameters)}`,
      null,
      {
        headers: {"x-api-key": process.env.VUE_APP_API_KEY},
      },
    );
  }

  async getCalculators({ queryStrings } = {}) {
    let url = `${process.env.VUE_APP_CORE_V1_BASE_URL}/${this.type}`;

    return axios.get(url, {
      params: {
        stage: "staging",
        ...queryStrings,
      },
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, { arrayFormat: "repeat"});
      },
    });
  }

  async readCalculator({pathParameters, queryStrings} = {}){
    let url = `${process.env.VUE_APP_CORE_V1_BASE_URL}/${this.type}/%(id)s`;
    return axios.get(`${sprintf(url, pathParameters)}`, {
      params: { stage: "staging", ...queryStrings },
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'});
      },
    });
  }

  async updateCalculator({pathParameters, data} = {}) {
    let url = `${process.env.VUE_APP_CORE_V1_BASE_URL}/${this.type}/%(id)s`;
    return axios.put(
      `${sprintf(url, pathParameters)}`,
      data,
      {
        headers: {"x-api-key": process.env.VUE_APP_API_KEY}
      },
    );
  }

  async commitCalculator({ pathParameters } = {}) {
    let url = `${process.env.VUE_APP_CORE_V1_BASE_URL}/${this.type}/%(entryId)s/actions/commit`;
    return axios.post(
      `${sprintf(url, pathParameters)}`,
      null,
      {
        headers: {"x-api-key": process.env.VUE_APP_API_KEY},
      },
    );
  }
}
