/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
import ability from "./utils/ability";
import moduleAuthState from "./store/auth/moduleAuthState";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          redirect: "/dashboard/analytics",
        },
        {
          path: "/dashboard/analytics",
          name: "dashboard-analytics",
          component: () => import("./views/DashboardAnalytics.vue"),
          meta: {
            action: "retrieve",
            resource: "dashboard",
          },
        },
        // =============================================================================
        // Application Routes
        // =============================================================================
        {
          path: "/tutorials",
          name: "tutorials",
          component: () => import("./views/Tutorials.vue"),
          meta: {
            action: "retrieve",
            resource: "dashboard", // TODO: Rename to tutorials
          },
        },
        {
          path: "/icons",
          name: "icons",
          component: () => import("./views/Icons.vue"),
          meta: {
            action: "retrieve",
            resource: "dashboard", // TODO: Rename to tutorials
          },
        },
        {
          path: "/stores",
          name: "stores",
          component: () => import("./views/stores/EntriesList.vue"),
          meta: {
            action: "list",
            resource: "store",
          },
        },
        {
          path: "/stores/create",
          name: "create-store",
          component: () => import("./views/stores/CUEntryForm.vue"),
          meta: {
            action: "create",
            resource: "store",
          },
        },
        {
          path: "/stores/:id",
          name: "store",
          component: () => import("./views/stores/CUEntryForm.vue"),
          meta: {
            action: "retrieve",
            resource: "store",
          },
        },
        {
          path: "/products",
          name: "products",
          component: () => import("./views/products/EntriesList.vue"),
          meta: {
            action: "list",
            resource: "product",
          },
        },
        {
          path: "/products/create",
          name: "create-product",
          component: () => import("./views/products/CUEntryForm.vue"),
          meta: {
            action: "create",
            resource: "product",
          },
        },
        {
          path: "/products/:id",
          name: "product",
          component: () => import("./views/products/CUEntryForm.vue"),
          meta: {
            action: "retrieve",
            resource: "product",
          },
        },
        {
          path: "/products/:entryId/inbox",
          name: "products-inbox",
          component: () => import("./views/products/FormsList.vue"),
          meta: {
            action: "retrieve",
            resource: "product",
          },
        },
        {
          path: "/products/:entryId/inbox/generate-report",
          name: "generate-report-product-forms",
          component: () => import("./views/products/QuickReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "product",
          },
        },
        {
          path: "/products/:entryId/inbox/scheduled-reports",
          name: "list-scheduled-reports-product",
          component: () => import("./views/products/ScheduledReportsList.vue"),
          meta: {
            action: "retrieve",
            resource: "product",
          },
        },
        {
          path: "/products/:entryId/inbox/scheduled-reports/create",
          name: "create-scheduled-report-product",
          component: () => import("./views/products/CUScheduledReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "product",
          },
        },
        {
          path: "/products/:entryId/inbox/scheduled-reports/:reportId",
          name: "edit-scheduled-report-product",
          component: () => import("./views/products/CUScheduledReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "product",
          },
        },
        {
          path: "/assets",
          name: "assets",
          component: () => import("./views/assets/EntriesList.vue"),
          meta: {
            action: "list",
            resource: "asset",
          },
        },
        {
          path: "/assets/create",
          name: "create-asset",
          component: () => import("./views/assets/CUEntryForm.vue"),
          meta: {
            action: "create",
            resource: "asset",
          },
        },
        {
          path: "/assets/:id",
          name: "asset",
          component: () => import("./views/assets/CUEntryForm.vue"),
          meta: {
            action: "retrieve",
            resource: "asset",
          },
        },
        {
          path: "/assets/:id/inbox",
          name: "assets-inbox",
          component: () => import("./views/assets/FormsList.vue"),
          meta: {
            action: "retrieve",
            resource: "asset",
          },
        },
        {
          path: "/assets/:entryId/inbox/quick-report/create",
          name: "generate-report-asset-forms",
          component: () => import("./views/assets/QuickReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "asset",
          },
        },
        {
          path: "/assets/:entryId/inbox/scheduled-reports",
          name: "list-scheduled-reports-asset",
          component: () => import("./views/assets/ScheduledReportsList.vue"),
          meta: {
            action: "retrieve",
            resource: "asset",
          },
        },
        {
          path: "/assets/:entryId/inbox/scheduled-reports/create",
          name: "create-scheduled-report-asset",
          component: () => import("./views/assets/CUScheduledReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "asset",
          },
        },
        {
          path: "/assets/:entryId/inbox/scheduled-reports/:reportId",
          name: "edit-scheduled-report-asset",
          component: () => import("./views/assets/CUScheduledReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "asset",
          },
        },
        {
          path: "/cities",
          name: "cities",
          component: () => import("./views/cities/EntriesList"),
          meta: {
            action: "list",
            resource: "city",
          },
        },
        {
          path: "/jobs",
          name: "jobs",
          component: () => import("./views/jobs/EntriesList.vue"),
          meta: {
            action: "list",
            resource: "job",
          },
        },
        {
          path: "/jobs/create",
          name: "create-job",
          component: () => import("./views/jobs/CUEntryForm.vue"),
          meta: {
            action: "create",
            resource: "job",
          },
        },
        {
          path: "/jobs/:id",
          name: "job",
          component: () => import("./views/jobs/CUEntryForm.vue"),
          meta: {
            action: "retrieve",
            resource: "job",
          },
        },
        {
          path: "/jobs/:id/inbox",
          name: "jobs-inbox",
          component: () => import("./views/jobs/FormsList.vue"),
          meta: {
            action: "retrieve",
            resource: "job",
          },
        },
        {
          path: "/jobs/:entryId/inbox/quick-report/create",
          name: "generate-report-job-forms",
          component: () => import("./views/jobs/QuickReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "job",
          },
        },
        {
          path: "/jobs/:entryId/inbox/scheduled-reports",
          name: "list-scheduled-reports-job",
          component: () => import("./views/jobs/ScheduledReportsList.vue"),
          meta: {
            action: "retrieve",
            resource: "job",
          },
        },
        {
          path: "/jobs/:entryId/inbox/scheduled-reports/create",
          name: "create-scheduled-report-job",
          component: () => import("./views/jobs/CUScheduledReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "job",
          },
        },
        {
          path: "/jobs/:entryId/inbox/scheduled-reports/:reportId",
          name: "edit-scheduled-report-job",
          component: () => import("./views/jobs/CUScheduledReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "job",
          },
        },
        {
          path: "/payments",
          name: "payments",
          component: () => import("./views/payments/EntriesList.vue"),
          meta: {
            action: "list",
            resource: "payment",
          },
        },
        {
          path: "/payments/create",
          name: "create-payment",
          component: () => import("./views/payments/PaymentsForm.vue"),
          meta: {
            action: "create",
            resource: "payment",
          },
        },
        {
          path: "/payments/:id",
          name: "payment",
          component: () => import("./views/payments/PaymentsForm.vue"),
          meta: {
            action: "update",
            resource: "payment",
          },
        },
        {
          path: "/promotions",
          name: "promotions",
          component: () => import("./views/promotions/EntriesList.vue"),
          meta: {
            action: "list",
            resource: "promotion",
          },
        },
        {
          path: "/promotions/create",
          name: "create-promotion",
          component: () => import("./views/promotions/CUEntryForm.vue"),
          meta: {
            action: "create",
            resource: "promotion",
          },
        },
        {
          path: "/promotions/:id",
          name: "promotion",
          component: () => import("./views/promotions/CUEntryForm.vue"),
          meta: {
            action: "retrieve",
            resource: "promotion",
          },
        },
        {
          path: "/promotions/:entryId/inbox",
          name: "promotions-inbox",
          component: () => import("./views/promotions/FormsList.vue"),
          meta: {
            action: "retrieve",
            resource: "promotion",
          },
        },
        {
          path: "/promotions/:entryId/inbox/generate-report",
          name: "generate-report-promotion-forms",
          component: () => import("./views/promotions/QuickReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "promotion",
          },
        },
        {
          path: "/promotions/:entryId/inbox/scheduled-reports",
          name: "list-scheduled-reports-promotion",
          component: () =>
            import("./views/promotions/ScheduledReportsList.vue"),
          meta: {
            action: "retrieve",
            resource: "promotion",
          },
        },
        {
          path: "/promotions/:entryId/inbox/scheduled-reports/create",
          name: "create-scheduled-report-promotion",
          component: () =>
            import("./views/promotions/CUScheduledReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "promotion",
          },
        },
        {
          path: "/promotions/:entryId/inbox/scheduled-reports/:reportId",
          name: "edit-scheduled-report-promotion",
          component: () =>
            import("./views/promotions/CUScheduledReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "promotion",
          },
        },
        {
          path: "/calculators",
          name: "calculators",
          component: () => import("./views/calculators/EntriesList.vue"),
          meta: {
            action: "list",
            resource: "calculator",
          },
        },
        {
          path: "/calculators/:id/vehicle",
          name: "calculator-prestamo-vehicular",
          component: () => import("./views/calculators/VehicleCalculatorForm.vue"),
          meta: {
            action: "update",
            resource: "calculator",
          },
        },
        {
          path: "/calculators/:id/personal",
          name: "calculator-prestamo-personal",
          component: () => import("./views/calculators/PersonalCalculatorForm.vue"),
          meta: {
            action: "update",
            resource: "calculator",
          },
        },
        {
          path: "/calculators/:id/dabuenavida",
          name: "calculator-prestamo-dabuenavida",
          component: () => import("./views/calculators/DabuenavidaCalculator.vue"),
          meta: {
            action: "update",
            resource: "calculator",
          },
        },
        {
          path: "/providers",
          name: "providers",
          component: () => import("./views/providers/EntriesList.vue"),
          meta: {
            action: "list",
            resource: "provider",
          },
        },
        {
          path: "/providers/create",
          name: "create-provider",
          component: () => import("./views/providers/ProvidersForm.vue"),
          meta: {
            action: "create",
            resource: "provider",
          },
        },
        {
          path: "/providers/:id",
          name: "provider",
          component: () => import("./views/providers/ProvidersForm.vue"),
          meta: {
            action: "retrieve",
            resource: "provider",
          },
        },
        {
          path: "/documents",
          name: "documents",
          component: () => import("./views/documents/EntriesList.vue"),
          meta: {
            action: "list",
            resource: "document",
          },
        },
        {
          path: '/documents/create',
          name: 'create-document',
          component: () => import('./views/documents/CUDocumentForm.vue'),
          meta: {
            action: 'create',
            resource: 'document',
          }
        },
        {
          path: "/documents/categories",
          name: "categories",
          component: () => import("./views/documents/CUDCategorySubcategoryForm.vue"),
          meta: {
            action: "list",
            resource: "document",
          }
        },
        {
          path: '/documents/:id',
          name: 'read-document',
          component: () => import('./views/documents/CUDocumentForm.vue'),
          meta: {
            action: 'create',
            resource: 'document',
          }
        },
        {
          path: "/:institution_id/static-pages",
          name: "static-pages-list",
          component: () => import("./views/static-pages/StaticPagesList.vue"),
          meta: {
            action: "list",
            resource: "static-page",
          },
        },
        {
          path: "/:institution_id/static-pages/:category",
          name: "single-category-static-pages-list",
          component: () =>
            import("./views/static-pages/SingleCategoryStaticPagesList.vue"),
          meta: {
            action: "list",
            resource: "static-page",
          },
        },
        {
          path: "/:institution_id/static-pages/:category/create",
          name: "create-static-page",
          component: () => import("./views/static-pages/CUStaticPageForm.vue"),
          meta: {
            action: "create",
            resource: "static-page",
          },
        },
        {
          path: "/:institution_id/static-pages/:category/:id",
          name: "static-page",
          component: () => import("./views/static-pages/CUStaticPageForm.vue"),
          meta: {
            action: "retrieve",
            resource: "static-page",
          },
        },
        {
          path: "/:institution_id/static-pages/:category/:id/forms",
          name: "static-page-forms",
          component: () => import("./views/static-pages/FormsList.vue"),
          meta: {
            action: "retrieve",
            resource: "static-page",
          },
        },
        {
          path:
            "/:institution_id/static-pages/:category/:id/forms/generate-report",
          name: "generate-report-static-page-forms",
          component: () =>
            import("./views/static-pages/RequestReportGenerationForm.vue"),
          meta: {
            action: "retrieve",
            resource: "static-page",
          },
        },
        {
          path: "/salvages",
          name: "salvages",
          component: () => import("./views/salvages/EntriesList.vue"),
          meta: {
            action: "list",
            resource: "salvage",
          },
        },
        {
          path: "/salvages/create",
          name: "create-salvage",
          component: () => import("./views/salvages/CUEntryForm.vue"),
          meta: {
            action: "create",
            resource: "salvage",
          },
        },
        {
          path: "/salvages/:id",
          name: "salvage",
          component: () => import("./views/salvages/CUEntryForm.vue"),
          meta: {
            action: "retrieve",
            resource: "salvage",
          },
        },
        {
          path: "/salvages/:id/inbox",
          name: "salvages-inbox",
          component: () => import("./views/salvages/FormsList.vue"),
          meta: {
            action: "retrieve",
            resource: "salvage",
          },
        },
        {
          path: '/salvages/:entryId/inbox/quick-report/create',
          name: 'generate-report-salvage-forms',
          component: () => import('./views/salvages/QuickReportForm.vue'),
          meta: {
            action: "retrieve",
            resource: "salvage",
          },
        },
        {
          path: "/salvages/:entryId/inbox/scheduled-reports",
          name: "list-scheduled-reports-salvage",
          component: () => import("./views/salvages/ScheduledReportsList.vue"),
          meta: {
            action: "retrieve",
            resource: "salvage",
          },
        },
        {
          path: "/salvages/:entryId/inbox/scheduled-reports/create",
          name: "create-scheduled-report-salvage",
          component: () => import("./views/salvages/CUScheduledReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "salvage",
          },
        },
        {
          path: "/salvages/:entryId/inbox/scheduled-reports/:reportId",
          name: "edit-scheduled-report-salvage",
          component: () => import("./views/salvages/CUScheduledReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "salvage",
          },
        },
        {
          path: "/terminologies",
          name: "terminologies",
          component: () => import("./views/terminologies/Terminologies.vue"),
          meta: {
            action: "retrieve",
            resource: "terminology",
          },
        },
        {
          path: "/real-state-projects",
          name: "real-state-projects",
          component: () =>
            import("./views/real-state-projects/EntriesList.vue"),
          meta: {
            action: "list",
            resource: "project",
          },
        },
        {
          path: "/real-state-projects/create",
          name: "create-real-state-project",
          component: () =>
            import("./views/real-state-projects/CUEntryForm.vue"),
          meta: {
            action: "create",
            resource: "project",
          },
        },
        {
          path: "/real-state-projects/recycle-bin",
          name: "deleted-real-state-projects",
          component: () =>
            import("./views/real-state-projects/DeletedEntriesList.vue"),
          meta: {
            action: "list",
            resource: "project",
          },
        },
        {
          path: "/real-state-projects/financing-options",
          name: "create-financing-options-real-state-projects",
          component: () =>
            import("./views/real-state-projects/CUFinancingOptions.vue"),
          meta: {
            action: "create",
            resource: "project",
          },
        },
        {
          path: "/real-state-projects/:id",
          name: "real-state-project",
          component: () =>
            import("./views/real-state-projects/CUEntryForm.vue"),
          meta: {
            action: "retrieve",
            resource: "project",
          },
        },
        {
          path: "/real-state-projects/:id/inbox",
          name: "real-state-projects-inbox",
          component: () => import("./views/real-state-projects/FormsList.vue"),
          meta: {
            action: "retrieve",
            resource: "project",
          },
        },
        {
          path: "/real-state-projects/:entryId/inbox/quick-report/create",
          name: "generate-report-real-state-projects-forms",
          component: () =>
            import("./views/real-state-projects/QuickReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "project",
          },
        },
        {
          path: "/real-state-projects/:entryId/inbox/scheduled-reports",
          name: "list-scheduled-reports-real-state-projects",
          component: () =>
            import("./views/real-state-projects/ScheduledReportsList.vue"),
          meta: {
            action: "retrieve",
            resource: "project",
          },
        },
        {
          path: "/real-state-projects/:entryId/inbox/scheduled-reports/create",
          name: "create-scheduled-report-real-state-projects",
          component: () =>
            import("./views/real-state-projects/CUScheduledReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "project",
          },
        },
        {
          path:
            "/real-state-projects/:entryId/inbox/scheduled-reports/:reportId",
          name: "edit-scheduled-report-real-state-projects",
          component: () =>
            import("./views/real-state-projects/CUScheduledReportForm.vue"),
          meta: {
            action: "retrieve",
            resource: "project",
          },
        },
        {
          path: "/notification-settings",
          name: "notifications",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsList.vue"
            ),
          meta: {
            action: "list",
            resource: "notification-settings",
          },
        },
        {
          path: "/notification-settings/:entry_type",
          name: "notification-settings",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsListEntryType.vue"
            ),
          meta: {
            action: "list",
            resource: "notification-settings",
          },
        },
        {
          path: "/:entry_type/inbox/scheduled-reports",
          name: "list-scheduled-report-notifications-settings",
          component: () =>
            import("./views/notifications-settings/ScheduledReportsList"),
          meta: {
            action: "list",
            resource: "notification-settings",
          },
        },
        {
          path: "/:entry_type/inbox/scheduled-reports/create",
          name: "create-scheduled-report-notifications-settings",
          component: () =>
            import("./views/notifications-settings/ScheduledReportFormCU.vue"),
          meta: {
            action: "create",
            resource: "notification-settings",
          },
        },
        {
          path: "/:entry_type/inbox/scheduled-reports/:id/update",
          name: "update-scheduled-report-notifications-settings",
          component: () =>
            import("./views/notifications-settings/ScheduledReportFormCU.vue"),
          meta: {
            action: "update",
            resource: "notification-settings",
          },
        },
        {
          path: "/notification-settings/assets/create",
          name: "notification-settings-create-assets",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsFormAssets.vue"
            ),
          meta: {
            action: "create",
            resource: "notification-settings",
          },
        },
        {
          path: "/notification-settings/assets/:id/update",
          name: "notification-settings-update-assets",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsFormAssets.vue"
            ),
          meta: {
            action: "update",
            resource: "notification-settings",
          },
        },
        {
          path: "/notification-settings/jobs/create",
          name: "notification-settings-create-jobs",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsFormJobs.vue"
            ),
          meta: {
            action: "create",
            resource: "notification-settings",
          },
        },
        {
          path: "/notification-settings/jobs/:id/update",
          name: "notification-settings-update-jobs",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsFormJobs.vue"
            ),
          meta: {
            action: "update",
            resource: "notification-settings",
          },
        },
        {
          path: "/notification-settings/promotions/create",
          name: "notification-settings-create-promotions",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsFormPromotions.vue"
            ),
          meta: {
            action: "create",
            resource: "notification-settings",
          },
        },
        {
          path: "/notification-settings/promotions/:id/update",
          name: "notification-settings-update-promotions",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsFormPromotions.vue"
            ),
          meta: {
            action: "update",
            resource: "notification-settings",
          },
        },
        {
          path: "/notification-settings/products/create",
          name: "notification-settings-create-products",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsFormProducts.vue"
            ),
          meta: {
            action: "create",
            resource: "notification-settings",
          },
        },
        {
          path: "/notification-settings/products/:id/update",
          name: "notification-settings-update-products",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsFormProducts.vue"
            ),
          meta: {
            action: "update",
            resource: "notification-settings",
          },
        },
        {
          path: "/notification-settings/projects/create",
          name: "notification-settings-create-projects",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsFormProjects.vue"
            ),
          meta: {
            action: "create",
            resource: "notification-settings",
          },
        },
        {
          path: "/notification-settings/projects/:id/update",
          name: "notification-settings-update-projects",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsFormProjects.vue"
            ),
          meta: {
            action: "update",
            resource: "notification-settings",
          },
        },
        {
          path: "/notification-settings/salvages/create",
          name: "notification-settings-create-salvages",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsFormSalvages.vue"
            ),
          meta: {
            action: "create",
            resource: "notification-settings",
          },
        },
        {
          path: "/notification-settings/salvages/:id/update",
          name: "notification-settings-update-salvages",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsFormSalvages.vue"
            ),
          meta: {
            action: "update",
            resource: "notification-settings",
          },
        },
        {
          path: "/notification-settings/static-pages/create",
          name: "notification-settings-create-static-pages",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsFormStaticPages.vue"
            ),
          meta: {
            action: "create",
            resource: "notification-settings",
          },
        },
        {
          path: "/notification-settings/static-pages/:id/update",
          name: "notification-settings-update-static-pages",
          component: () =>
            import(
              "./views/notifications-settings/NotificationsSettingsFormStaticPages.vue"
            ),
          meta: {
            action: "update",
            resource: "notification-settings",
          },
        },
        {
          path: "/media-objects",
          name: "media-objects",
          component: () => import("./views/media-objects/List.vue"),
          meta: {
            action: "list",
            resource: "media-object",
          },
        },
        {
          path: "/media-objects/banners/:institution/:type",
          name: "media-object-banner",
          component: () => import("./views/media-objects/CUBannerForm.vue"),
          meta: {
            action: "create",
            resource: "media-object",
          },
        },
        {
          path: "/media-objects/slides/:institution/:type",
          name: "media-object-slides",
          component: () => import("./views/media-objects/SlidesList.vue"),
          meta: {
            action: "create",
            resource: "media-object",
          },
        },
        {
          path: "/media-objects/slides/:institution/:type/create",
          name: "create-media-object-slide",
          component: () => import("./views/media-objects/CUSlideForm.vue"),
          meta: {
            action: "create",
            resource: "media-object",
          },
        },
        {
          path: "/media-objects/slides/:institution/:type/:id",
          name: "media-object-slide",
          component: () => import("./views/media-objects/CUSlideForm.vue"),
          meta: {
            action: "retrieve",
            resource: "media-object",
          },
        },
        {
          path: "/menus",
          name: "menus",
          component: () => import("./views/menus/EntriesList.vue"),
          meta: {
            action: "list",
            resource: "menu",
          },
        },
        {
          path: "/menus/create",
          name: "create-menu",
          component: () => import("./views/menus/CUEntryForm.vue"),
          meta: {
            action: "create",
            resource: "menu",
          },
        },
        {
          path: "/menus/:id",
          name: "menu",
          component: () => import("./views/menus/CUEntryForm.vue"),
          meta: {
            action: "update",
            resource: "menu",
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        // {
        //   path: '/callback',
        //   name: 'auth-callback',
        //   component: () => import('@/views/Callback.vue'),
        //   meta: {
        //     rule: 'editor'
        //   }
        // },
        {
          path: "/pages/login",
          name: "page-login",
          component: () => import("@/views/pages/Login.vue"),
          meta: {},
        },
        {
          path: "/pages/register",
          name: "page-register",
          component: () => import("@/views/pages/Register.vue"),
          meta: {},
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {},
        },
        {
          path: "/pages/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {},
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404",
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  const canNavigate =
    to.path === "/pages/login" ||
    to.path === "/pages/error-404" ||
    to.path === "/pages/error-500" ||
    to.path === "/pages/register" ||
    to.path === "/callback"
      ? true
      : to.matched.some((route) => {
          return ability.can(route.meta.action, route.meta.resource);
        });

  if (!canNavigate) {
    if (moduleAuthState.isUserLoggedIn()) {
      router.push({ path: "/pages/error-404", query: { to: to.path } });
    } else {
      router.push({ path: "/pages/login", query: { to: to.path } });
    }
  }
  next();
});

export default router;
