// /*=========================================================================================
//   File Name: moduleAuthState.js
//   Description: Auth Module State
//   ----------------------------------------------------------------------------------------
//   Item Name: Vuesax Admin - VueJS Dashboard Admin Template
//   Author: Pixinvent
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================*/
export default {
  isUserLoggedIn: () => {
    return (localStorage.hasOwnProperty("userInfo") && localStorage.hasOwnProperty("permissions"));
  }
}
