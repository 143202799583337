<template>
  <form data-vv-scope="component-data">
    <div class="vx-row">
      <div class="vx-col md:w-1/2 w-full">
        <label for="label" class="w-full select-large">Título</label>
        <editor id="title" name="title" :content.sync="data.settings.title.html" v-validate="'required'"/>
        <span class="text-danger">{{ errors.first("component-data.title") }}</span>
      </div>
    </div>

    <div class="vx-row mt-5">
      <div class="vx-col w-1/2">
        <label for="action">Ícono <a href="/icons" target="_blank">(ver listado de íconos)</a></label>
        <vs-input id="icon" name="icon" v-model="data.settings.icon_name" class="w-full"/>
      </div>
    </div>

    <vs-divider class="vx-col w-full mt-5" position="left" color="light">Menú</vs-divider>

    <collapsable-card
      v-for="(component, index) in components" :title="`${index + 1}. ${componentNameMapper[component.value]}`"
      class="mt-10"
    >
      <template #header-actions-right>
        <vs-button icon-pack="feather" icon="icon-trash-2" type="flat" @click="removeItem(index)"/>
      </template>

      <div class="card-body">
        <component :is="component.value" v-model="data.components[index]" :institution="institution" :key="component.id"/>
      </div>
    </collapsable-card>

    <vs-divider class="vx-col w-full mt-5" position="left" color="light"/>

    <div class="vx-row mt-5">
      <menu-component-selector class="vx-col w-full lg:w-1/3" mode="all" @input="addItem($event)"/>
    </div>

  </form>
</template>

<script>
  import vSelect from "vue-select";
  import { v4 as uuid4 } from "uuid";

  import BaseMenuComponent from "@/views/menus/components/BaseMenuComponent.vue";
  import CollapsableCard from "@/views/menus/components/CollapsableCard.vue";
  import Editor from "@/components/Editor.vue";
  import MenuComponentSelector from "@/views/menus/components/MenuComponentSelector.vue";
  import Notifier from "@/utils/notification";

  const notifier = new Notifier();

  export default {
    name: "MenuWrapperItem",

    extends: BaseMenuComponent,

    components: {
      CollapsableCard,
      Editor,
      MenuComponentSelector,
      vSelect,
    },

    created(){
      this.data.type = "menu-wrapper-item"
    },

    mounted(){
      this.initializeComponent();
      this.components = this.data.components.map((component) => {
        return {
          id: uuid4(),
          value: component.type,
        }
      });
    },

    methods: {
      initializeComponent(){
        const defaultSettings = {
          title: {},
          icon_name: "",
        }
        this.data.settings = {...defaultSettings, ...this.data.settings};
      }
    },
  }
</script>

<style scoped>
  .card-body {
    padding: 15px;
  }
</style>
