// /*=========================================================================================
//   File Name: moduleAuthActions.js
//   Description: Auth Module Actions
//   ----------------------------------------------------------------------------------------
//   Item Name: Vuesax Admin - VueJS Dashboard Admin Template
//   Author: Pixinvent
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================*/
import router from '@/router'
import axios from 'axios'


export default {
  loginAttempt({dispatch}, payload) {
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
      closeAnimation: payload.closeAnimation
    };
    dispatch('login', newPayload)
  },

  login({commit, state, dispatch}, payload) {
    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      // Close animation if passed as payload
      if (payload.closeAnimation) payload.closeAnimation();

      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      });

      return false
    }

    axios.post(
      process.env.VUE_APP_AUTH_URL,
      {},
      {auth: {username: payload.userDetails.peopleSoft, password: payload.userDetails.password}}
    )
      .then(response => {
        if (payload.closeAnimation) payload.closeAnimation();
        localStorage.setItem('userInfo', JSON.stringify({userName: response.data.user_name}));
        localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
        router.push(router.currentRoute.query.to || '/');
      })
      .catch(error => {
        if (payload.closeAnimation) payload.closeAnimation();

        payload.notify({
          time: 2500,
          title: 'Error de autenticación',
          text: "El usuario o contraseña inválida. Intentelo nuevamente.",
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        });
      });
  },
}
