<script>
  import {v4 as uuid4} from "uuid";

  import {ComponentsNameMapper} from "@/views/menus/utils/constants";
  import { EventBus } from "@/utils/event-bus";

  export default {
    name: "BaseMenuComponent",

    props: {
      institution: {
        type: String,
        required: true,
      }
    },

    data(){
      return {
        data: {
          type: "",
          settings: {
            title: {},
            description: {},
            icon_name: "",
          },
          components: [],
        },
        components: [],
      };
    },

    computed: {
      componentNameMapper(){
        return ComponentsNameMapper;
      }
    },

    watch: {
      data: {
        handler(newVal){
          const sanitizedData = Object.entries(newVal.settings).reduce((acc, [key, value]) => {
            if (typeof value === "object" && Object.keys(value).length !== 0) {
              acc[key] = value;
            } else if (value) {
              acc[key] = value;
            }

            return acc;
          }, {})

          this.$emit("input", {
            type: newVal.type,
            settings: sanitizedData,
            components: newVal.components,
          });
        },
        deep: true,
      }
    },

    created(){
      EventBus.$on("validate-fields", () => {
        this.$validator.validateAll("component-data").then((result) => {
          EventBus.$emit("validation-state", result);
        });
      });
    },

    mounted(){
      if (this.$route.params.id && this.$attrs.value){
        this.data = {...this.data, ...this.$attrs.value};
      }
    },

    methods:{
      addItem(item){
        this.components.push({
          id: uuid4(),
          value: item.value
        });
      },

      removeItem(index){
        this.components.splice(index, 1);
        if (this.data.components.length > index){
          this.data.components.splice(index, 1);
          this.$emit("input", this.data);
        }
      },
    }
  }
</script>
