// /*=========================================================================================
//   File Name: moduleAuthMutations.js
//   Description: Auth Module Mutations
//   ----------------------------------------------------------------------------------------
//   Item Name: Vuesax Admin - VueJS Dashboard Admin Template
//   Author: Pixinvent
//   Author URL: http://www.themeforest.net/user/pixinvent
// ==========================================================================================*/
//
//
export default {
	UPDATE_AUTHENTICATED_USER(state, data) {
		localStorage.setItem('userInfo', JSON.stringify(data.userInfo));
		localStorage.setItem('permissions', JSON.stringify(data.roles));
	},
}
