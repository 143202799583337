/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import App from './App.vue'
import Uploader from './components/Uploader.vue';
import DetailedTileItem from "@/views/menus/components/items/DetailedTileItem.vue";
import ListTextItem from "@/views/menus/components/items/ListTextItem.vue";
import ListWrapper from "@/views/menus/components/wrappers/ListWrapper.vue";
import MobileMenuWrapper from "@/views/menus/components/wrappers/MobileMenuWrapper.vue";
import MenuWrapperItem from "@/views/menus/components/wrappers/MenuWrapperItem.vue";
import SimpleListItem from "@/views/menus/components/items/SimpleListItem.vue";
import SimpleTileItem from "@/views/menus/components/items/SimpleTileItem.vue";
import TilesWrapper from "@/views/menus/components/wrappers/TilesWrapper.vue";


Vue.component('uploader', Uploader);
Vue.component("detailed-tile-item", DetailedTileItem);
Vue.component("list-text-item", ListTextItem);
Vue.component("list-wrapper", ListWrapper);
Vue.component("mobile-menu-wrapper", MobileMenuWrapper);
Vue.component("menu-wrapper-item", MenuWrapperItem);
Vue.component("simple-list-item", SimpleListItem);
Vue.component("simple-tile-item", SimpleTileItem);
Vue.component("tiles-wrapper", TilesWrapper);

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
Vue.use(Vuesax)


import qs from 'qs';
Vue.prototype.$qs = qs;

// axios
import axios from 'axios'
Vue.prototype.$http = axios

// Theme Configurations
import '../themeConfig.js'

import ability from "./utils/ability";
import { abilitiesPlugin } from '@casl/vue'
Vue.use(abilitiesPlugin, ability);
// // // Firebase
// // import '@/firebase/firebaseConfig'
// //
// //
// // // Auth0 Plugin
// // import AuthPlugin from "./plugins/auth";
// // Vue.use(AuthPlugin);


// ACL
// import acl from './acl/acl'


// Globally Registered Components
import './globalComponents.js'

// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css';


// Vue Router
import router from './router'


// Vuex Store
import store from './store/store'


// i18n
import i18n from './i18n/i18n'


// Vuesax Admin Filters
import './filters/filters'

// Vue-Sweetalert2
import Swal from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(Swal);

// Vue-Toasted
import Toasted from 'vue-toasted';
Vue.use(Toasted, {
  position: 'top-right',
  duration: 2000,
  action: {
    text: 'X',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    }
  },
});

// VeeValidate
import VeeValidate from 'vee-validate';
Vue.use(VeeValidate);


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer);


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
// require('./assets/css/icomoon.css.css');
require('./assets/css/iconfont.css');


// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
