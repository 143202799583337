<template>
  <div class="editor-wrapper">
    <vue-editor
      :id="id"
      v-model="editorContent"
      :editorOptions="editorSettings"
      :editor-toolbar="customToolbar"
      useCustomImageHandler
      @image-added="handleImageAdded"
      @image-removed="handleImageRemoved"
    />
  </div>
</template>

<script>
  import {VueEditor, Quill} from "vue2-editor";
  import ImageResize from "quill-image-resize";

  Quill.register("modules/imageResize", ImageResize);

  export default {
    name: "Editor",
    props: {
      id: {
        type: String,
        default: "editor"
      },
      content: String,
      resourceType: String
    },

    $_veeValidate: {
      value () {
        return this.content;
      },

      name () {
        return this.$attrs.name;
      }
    },

    data() {
      return {
        customHandler: true,
        customToolbar: [
          ["bold"],
          // ["bold", "italic", "underline", "strike"],
          // [{align: ""}, {align: "center"}, {align: "right"}, {align: "justify"}],
          // [{list: "ordered"}, {list: "bullet"}, {list: "check"}],
          // [{ direction: "rtl" }],
          // ["clean"],
        ],
        editorSettings: {
          useCustomImageHandler: true,
          modules: {
            imageResize: {}
          },
          formats: ["bold"],
        }
      };
    },
    methods: {
      handleImageAdded(file, Editor, cursorLocation, resetUploader) {
        this.$http
          .get(
            `${process.env.VUE_APP_RESOURCES_V1_BASE_URL}/presigned-url`,
            {
              params: {
                resource_type: this.resourceType,
                content_type: file.type,
                cache_control: process.env.VUE_APP_CACHE_CONTROL
              }
            }
          )
          .then(response => {
            this.$http
              .put(response.data.url, file, {headers: {"Content-Type": file.type, "Cache-Control": process.env.VUE_APP_CACHE_CONTROL}})
              .then(res => {
                if (res.status === 200) {
                  let url = response.data.url.split("?")[0];
                  Editor.insertEmbed(cursorLocation, "image", url);
                  resetUploader();
                }
              })
              .catch(error => {
                console.log(error);
              });
          })
          .catch(error => {
            console.log(error);
          });
      },
      handleImageRemoved(url) {
        let urlDelete = `${process.env.VUE_APP_RESOURCES_V1_BASE_URL}/`;
        let body = JSON.stringify({url: url});
        this.$http
          .delete(urlDelete, {data: body})
          .then(response => {
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    computed: {
      editorContent: {
        get() {
          return this.content;
        },
        set(updatedContent) {
          this.$emit("update:content", updatedContent);
        }
      }
    },
    components: {
      VueEditor
    }
  };
</script>

<style lang="css">
  @import "~vue2-editor/dist/vue2-editor.css";
</style>
