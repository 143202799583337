import axios from 'axios';

let qs = require('qs');
const sprintf = require('sprintf-js').sprintf;

export default class EntriesClient {
  constructor(type) {
    this.type = type;
  }

  async create({data} = {}) {
    let url = `${process.env.VUE_APP_ENTRIES_V1_BASE_URL}/${this.type}`;
    return axios({
      url: url,
      method: 'post',
      data: data,
      headers: {
        'x-api-key': process.env.VUE_APP_ENTRIES_V1_API_KEY
      }
    });
  }

  async retrieve({pathParameters, queryStrings} = {}) {
    let url = `${process.env.VUE_APP_ENTRIES_V1_BASE_URL}/${this.type}/%(entryId)s`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'get',
      params: {stage: "staging", ...queryStrings},
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'})
      },
    });
  }

  async update({pathParameters, data} = {}) {
    let url = `${process.env.VUE_APP_ENTRIES_V1_BASE_URL}/${this.type}/%(entryId)s`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'put',
      data: data,
      headers: {
        'x-api-key': process.env.VUE_APP_ENTRIES_V1_API_KEY
      }
    });
  }

  async delete({pathParameters} = {}) {
    let url = `${process.env.VUE_APP_ENTRIES_V1_BASE_URL}/${this.type}/%(entryId)s`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'delete',
      headers: {
        'x-api-key': process.env.VUE_APP_ENTRIES_V1_API_KEY
      }
    });
  }

  async all({fromRecycleBin = false, queryStrings} = {}) {
    let url = fromRecycleBin
      ? `${process.env.VUE_APP_ENTRIES_V1_BASE_URL}/deleted-${this.type}`
      : `${process.env.VUE_APP_ENTRIES_V1_BASE_URL}/${this.type}`;
    return axios({
      url: url,
      method: 'get',
      params: {stage: "staging", ...queryStrings},
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'})
      },
    });
  }

  async commit({pathParameters} = {}) {
    let url = `${process.env.VUE_APP_ENTRIES_V1_BASE_URL}/${this.type}/%(entryId)s/commit`;
    return axios({url: `${sprintf(url, pathParameters)}`, method: 'post'});
  }

  async restore({pathParameters} = {}) {
    let url = `${process.env.VUE_APP_ENTRIES_V1_BASE_URL}/deleted-${this.type}/%(entryId)s/restore`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'post',
      headers: {
        'x-api-key': process.env.VUE_APP_ENTRIES_V1_API_KEY
      }
    });
  }

  async requestReportGeneration({data} = {}) {
    let url = `${process.env.VUE_APP_ENTRIES_V1_BASE_URL}/${this.type}/report`;
    return axios({
      url: url,
      method: 'post',
      data: data,
    });
  }
}
