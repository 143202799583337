<template>
  <form data-vv-scope="component-data" @submit.prevent>
    <div class="vx-row">
      <div class="vx-col w-1/2">
        <label for="title" class="w-full select-large">Título</label>
        <editor
          id="title" name="title" :content.sync="data.settings.title.html" class="w-full" v-validate="'required'"
        />
        <span class="text-danger">{{ errors.first("component-data.title") }}</span>
      </div>
    </div>

    <vs-divider class="vx-col w-full mt-5" position="left" color="light"/>

    <div class="vx-row px-10 full-width">
      <collapsable-card
        v-for="(component, index) in components"
        :title="`${index + 1}. ${componentNameMapper[component.value]}`"
        class="vx-col w-full"
      >
        <template #header-actions-right>
          <vs-button icon-pack="feather" icon="icon-trash-2" type="flat" @click="removeItem(index)"/>
        </template>

        <div class="card-body">
          <component :is="component.value" v-model="data.components[index]" :institution="institution" :key="component.id"/>
        </div>
      </collapsable-card>
    </div>

    <vs-divider class="vx-col w-full mt-5" position="left" color="light"/>

    <div class="vx-row mt-5">
      <div class="vx-col w-1/2">
        <menu-component-selector mode="item" parent="list-wrapper" @input="addItem($event)" class="w-full"/>
      </div>
    </div>
  </form>
</template>

<script>
  import { v4 as uuid4 } from "uuid";

  import BaseMenuComponent from "@/views/menus/components/BaseMenuComponent.vue";
  import CollapsableCard from "@/views/menus/components/CollapsableCard.vue";
  import Editor from "@/components/Editor.vue";
  import MenuComponentSelector from "@/views/menus/components/MenuComponentSelector.vue";

  export default {
    name: "ListWrapper",

    extends: BaseMenuComponent,

    components: {
      CollapsableCard,
      Editor,
      MenuComponentSelector,
    },

    created(){
      this.data.type = "list-wrapper";
    },

    mounted(){
      this.initializeComponent();
      this.components = this.data.components.map((component) => {
        return {
          id: uuid4(),
          value: component.type,
        };
      });
    },

    methods: {
      initializeComponent(){
        const defaultSettings = {
          title: {},
        }
        this.data.settings = {...defaultSettings, ...this.data.settings};
      }
    },
  }
</script>

<style scoped>
  .card-body {
    padding: 15px;
  }
</style>
