<template>
  <form data-vv-scope="component-data" @submit.prevent>
    <div class="vx-row">
      <div class="vx-col w-1/2">
        <menu-item-destination v-model="data.settings.destination" :institution="institution"/>
      </div>
    </div>

    <div class="vs-row mt-5">
      <div class="vx-col">
        <vs-checkbox
          name="advancedConfiguration" v-model="advancedConfiguration"
          @change="advancedConfiguration = !advancedConfiguration"
        >
          Personalizar
        </vs-checkbox>
      </div>
    </div>

    <template v-if="advancedConfiguration">
      <div class="vx-row mt-5">
        <div class="vx-col w-1/2">
          <label for="title">Título</label>
          <editor id="title" name="title" :content.sync="data.settings.title.html" class="w-full" />
        </div>
      </div>

      <div class="vx-row mt-5">
        <div class="vx-col w-1/2">
          <label for="action">Ícono <a href="/icons" target="_blank">(ver listado de íconos)</a></label>
          <vs-input id="icon" name="icon" v-model="data.settings.icon_name" class="w-full"/>
        </div>
      </div>

      <div class="vx-row mt-5">
        <div class="vx-col w-1/2">
          <label for="custom_url">URL Personalizada (Opcional)</label>
          <vs-input id="custom_url" name="custom_url" v-model="data.settings.custom_url" class="w-full" />
        </div>
      </div>
    </template>
  </form>
</template>

<script>
  import BaseMenuComponent from "@/views/menus/components/BaseMenuComponent.vue";
  import Editor from "@/components/Editor.vue";
  import MenuItemDestination from "@/views/menus/components/MenuItemDestination.vue";

  export default {
    name: "ListItem" ,

    extends: BaseMenuComponent,

    components: {
      Editor,
      MenuItemDestination,
    },

    data(){
      return {
        advancedConfiguration: false,
      };
    },

    created(){
      this.data.type = "simple-list-item";
    },

    mounted(){
      this.initializeComponents();
    },

    methods: {
      initializeComponents(){
        const defaultSettings = {
          title: {},
          icon_name: "",
          custom_url: "",
        }
        this.data.settings = {...defaultSettings, ...this.data.settings};

        if (
            Object.keys(this.data.settings.title).length !== 0 ||
            this.data.settings.icon_name !== ""
        ){
          this.advancedConfiguration = true;
        }
      }
    },
  }
</script>
