import Vue from 'vue'
import moment from "moment";

Vue.filter('capitalize', function (value) {
  if (!value) return '';
  value = value.toString();
  let arr = value.split(" ");
  let capitalized_array = [];
  arr.forEach((word) => {
    let capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalized_array.push(capitalized)
  });
  return capitalized_array.join(" ");
});

Vue.filter('truncate', function (value, limit) {
  return value.substring(0, limit)
});

Vue.filter('tailing', function (value, tail) {
  return value + tail;
});

Vue.filter('time', function (value, is24HrFormat = false) {
  if (value) {
    const date = new Date(Date.parse(value));
    let hours = date.getHours();
    const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    if (!is24HrFormat) {
      const time = hours > 12 ? 'AM' : 'PM';
      hours = hours % 12 || 12;
      return hours + ':' + min + ' ' + time
    }
    return hours + ':' + min
  }
});

Vue.filter('date', function (value, fullDate = false) {
  value = String(value);
  const date = value.slice(8, 10).trim();
  const month = value.slice(4, 7).trim();
  const year = value.slice(11, 15);

  if (!fullDate) return date + ' ' + month;
  else return date + ' ' + month + ' ' + year;
});

Vue.filter('month', function (val, showYear = true) {
  val = String(val);

  const regx = /\w+\s(\w+)\s\d+\s(\d+)./;
  if (!showYear) {
    return regx.exec(val)[1];
  } else {
    return regx.exec(val)[1] + ' ' + regx.exec(val)[2];
  }

});

Vue.filter('csv', function (value) {
  return value.join(', ')
});

Vue.filter('filter_tags', function (value) {
  return value.replace(/<\/?[^>]+(>|$)/g, "")
});

Vue.filter('k_formatter', function (num) {
  return num > 999 ? (num / 1000).toFixed(1) + 'k' : num
});

Vue.filter('status', function (status) {
  var typeStatus = {
    'published': 'Publicado',
    'unpublished': 'No publicado',
    'expired': 'Vencido'
  };

  return typeStatus[status]
});

Vue.filter('publication_status_label', function (publicationStatus) {
  let mapper = {
    published: "Publicado",
    unpublished: "No publicado",
    expired: "Vencido",
    pending: "Pendiente",
    historic: "Histórico",
  };

  return mapper[publicationStatus];
});

Vue.filter("publication_status_color", function (publicationStatus) {
  let mapper = {
    published: "success",
    unpublished: "warning",
    expired: "danger",
    pending: "warning",
    historic: "danger",
  };

  return mapper[publicationStatus];
});

Vue.filter("document_type_label", function (documentType) {
  let mapper = {
    hosted: "Documento Interno",
    external: "Documento Externo",
  };

  return mapper[documentType];
});

Vue.filter("local_datetime", function (datetime) {
  return moment(datetime).local().format("YYYY-MM-DD HH:mm:ss");
});

Vue.filter("entry_url", function (entry, moduleSlug, stage) {
  let baseURL = stage === "staging" ? process.env.VUE_APP_STAGING_SITE_BASE_URL : process.env.VUE_APP_PUBLIC_SITE_BASE_URL;
  let entryPath = moduleSlug === "productos" ? `${entry.category.slug}/${entry.slug}` : `${entry.slug}`;

  return `${baseURL}/${entry.institution.slug}/${moduleSlug}/${entryPath}`;
});

Vue.filter("static_page_url", function (entry, stage) {
  let baseURL = stage === "staging" ? process.env.VUE_APP_STAGING_SITE_BASE_URL : process.env.VUE_APP_PUBLIC_SITE_BASE_URL;
  let path = entry.featured ? `${entry.category.slug}` : `${entry.category.slug}/${entry.slug}`;

  return `${baseURL}/${entry.institution.slug}/${path}`;
});

Vue.filter("periodicity_label", function (periodicity, execution_hour, dates) {
  let mapper = {
    every_monday: "Todos los lunes",
    every_tuesday: "Todos los martes",
    every_wednesday: "Todos los miércoles",
    every_thursday: "Todos los jueves",
    every_friday: "Todos los viernes",
    every_saturday: "Todos los sábados",
    every_sunday: "Todos los domingos",
    first_day_of_each_month: "El primer día de cada mes",
    last_day_of_each_month: "El último día de cada mes",
    specific_dates: "Fechas personalizadas",
  };

  if (periodicity === "specific_dates"){
    return `${dates.toString()} de cada mes a las ${execution_hour.toString().padStart(2, "0")}:00 horas`;
  } else{
    return `${mapper[periodicity]} a las ${execution_hour.toString().padStart(2, "0")}:00 horas`;
  }
});
