<template>
  <div v-bind="$attrs" class="flex">
      <div class="vx-col w-2/3">
        <label for="component-type">Agregar {{ mode === modes.WRAPPER ? "Contenedor" : "Elemento" }}</label>
        <v-select
          name="component-type" v-model="selectedComponent" class="w-full" placeholder="Seleccione..."
          :searchable="false" :options="components"
        />
      </div>
      <div class="vx-col w-1/3 flex pt-6 pl-5">
        <vs-button radius size="medium" color="success" icon-pack="feather" icon="icon-plus" @click="addComponent"/>
      </div>
  </div>
</template>

<script>
  import vSelect from "vue-select";

  import { MENU_WRAPPER_OPTIONS, MENU_ITEM_OPTIONS } from "@/views/menus/utils/constants";

  const MODES = {
    ALL: "all",
    WRAPPER: "wrapper",
    ITEM: "item",
  }

  export default {
    name: "MenuComponentSelector",

    components: {
      vSelect,
    },

    props: {
      mode: {
        type: String,
        required: true
      },

      parent: {
        type: String,
        required: false,
      },
    },

    data(){
      return {
        components: [],
        selectedComponent: null,
      }
    },

    computed: {
      modes(){
        return MODES;
      },
    },

    beforeMount() {
      if (this.mode === MODES.WRAPPER){
        this.components = MENU_WRAPPER_OPTIONS;
      } else if (this.mode === MODES.ITEM) {
        this.components = MENU_ITEM_OPTIONS[this.parent]
      } else {
        this.components = [].concat(MENU_WRAPPER_OPTIONS, MENU_ITEM_OPTIONS.ALL);
      }
    },

    methods: {
      addComponent(){
        this.$emit("input", this.selectedComponent);
        this.selectedComponent = null;
      },
    },
  }
</script>

<style scoped>

</style>
