<template>
  <vs-card>
    <template #header>
      <div class="vx-row px-5 flex">
        <div class="vx-col w-2/3 flex" style="align-items:center">
          <slot name="header-actions-left"></slot>
          {{title}}
        </div>
        <div class="vx-col w-1/3 flex" style="align-items: center; justify-content: end">
          <slot name="header-actions-right"></slot>
          <vs-button icon-pack="feather" :icon="collapsedButtonIcon" type="flat" @click="collapsed = !collapsed"/>
        </div>
      </div>
    </template>
    <div v-show="collapsed === false">
      <slot></slot>
    </div>
  </vs-card>
</template>

<script>
  export default {
    name: "CollapsableCard",

    props: {
      title: {
        type: String,
        required: true,
      },
    },

    data(){
      return {
        collapsed: false
      };
    },

    computed: {
      collapsedButtonIcon(){
        return (this.collapsed ? "icon-maximize-2" : "icon-minimize-2");
      }
    },
  }
</script>

<style>
  .vs-card--content{
    font-size: 1rem !important;
  }
</style>
