import axios from 'axios'

let qs = require('qs');

export default class Upload {
  constructor(type) {
    this.type = type;
  }

  async uploadFiles({pathParameters, queryStrings, files, multiple} = {}) {
    queryStrings["cache_control"] = process.env.VUE_APP_CACHE_CONTROL
    return await axios({
      url: `${process.env.VUE_APP_RESOURCES_V1_BASE_URL}/presigned-url`,
      method: 'get',
      params: queryStrings,
      paramsSerializer: function (queryStrings) {
        return qs.stringify(queryStrings, {arrayFormat: 'repeat'})
      },
    }).then(response => {
      let resourceURL = response.data.url.split("?")[0];
      let filesToUpload = {};
      multiple ? filesToUpload = files : filesToUpload = files[0];
      axios.put(response.data.url, filesToUpload.data, {headers: {"Content-Type": filesToUpload.type, "Cache-Control": process.env.VUE_APP_CACHE_CONTROL}});
      return resourceURL;
    });
  }

  async removeFiles({data} = {}) {
    await axios({
      url: `${process.env.VUE_APP_RESOURCES_V1_BASE_URL}/`,
      method: 'delete',
      data: data
    });
  }
}
