<template>
  <form data-vv-scope="component-data">
    <div class="vx-row">
      <div class="vx-col w-1/2">
        <label for="title">Título</label>
        <editor
            id="title" name="title" :content.sync="data.settings.title.html" v-validate="'required'" class="w-full"
        />
        <span class="text-danger">{{ errors.first("component-data.title") }}</span>
      </div>
    </div>

    <div class="vx-row mt-5">
      <div class="vx-col w-1/2">
        <label for="title">Descripción</label>
        <editor
          id="description" name="description" :content.sync="data.settings.description.html" v-validate="'required'"
          class="w-full"
        />
        <span class="text-danger">{{ errors.first("component-data.description") }}</span>
      </div>
    </div>
  </form>
</template>

<script>
  import BaseMenuComponent from "@/views/menus/components/BaseMenuComponent.vue";
  import Editor from "@/components/Editor.vue";

  export default {
    name: "ListTextItem",

    extends: BaseMenuComponent,

    components: {
      Editor,
    },

    created(){
      this.data.type = "list-text-item";
    },

    mounted(){
      this.initializeComponent();
    },

    methods: {
      initializeComponent(){
        const defaultSettings = {
          title: {},
          description: {},
        };
        this.data.settings = {...defaultSettings, ...this.data.settings};
      }
    },
  }
</script>

<style scoped>

</style>
